.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#40475d, 0.02);
  max-width: 100%;
  z-index: 100;

  .block {
    padding: 22px 21px 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(64, 71, 93, 0.1);
    min-width: 400px;
    overflow-y: auto;
  }

  .close {
    top: 25px;
    right: 21px;
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: transparent;
    border: 0;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin-left: -10px;
      margin-top: -10px;
      width: 1.5px;
      height: 18px;
      background-color: #9fa2ae;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.backDrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(#40475d, 0.3) !important;
}

.clearIcon {
  svg {
    width: 14px;
    position: relative;
    right: 18px;
    bottom: 1px;
  }
  & > div > div > div {
    padding-right: 10px;
  }
}

.readDropdown {
  svg {
    width: 14px;
    position: relative;
    right: 18px;
    bottom: 1px;
  }
  & > div > div > div {
    padding-right: 10px;
  }
  & div:hover {
    cursor: not-allowed;
  }
}

.inputWrap {
  .input {
    text-align: center;

    input {
      margin: 0 auto 14px;
      font-size: 14px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.0001);
      border: 1px solid #e6e7ed;
      border-radius: 4px;
      max-height: 40px;
      height: 100%;
      width: 100%;
      max-width: 375px;
    }

    .error {
      border: 1px solid #ff4f5b;

      &:focus {
        box-shadow: 0 0 0 0.2rem #ffdcde;
      }
    }

    ::placeholder {
      color: #9fa2ae;
    }
  }
  .inputReadOnly {
    text-align: center;

    & input:hover {
      cursor: not-allowed;
    }

    input {
      margin: 0 auto 14px;
      font-size: 14px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.0001);
      border: 1px solid #e6e7ed;
      border-radius: 4px;
      max-height: 40px;
      height: 100%;
      width: 100%;
      max-width: 375px;
    }

    .error {
      border: 1px solid #ff4f5b;

      &:focus {
        box-shadow: 0 0 0 0.2rem #ffdcde;
      }
    }

    ::placeholder {
      color: #9fa2ae;
    }
  }
}

.fieldError {
  border: 1px solid #ff4f5b;

  &:focus {
    box-shadow: 0 0 0 0.2rem #ffdcde;
  }
}

.dropdownWrap {
  margin-bottom: 24px;
}

p {
  margin-bottom: 5px;
  text-align: start;
  color: #9fa2ae;
}

.header {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 21px;
}

.subheader {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.title {
  line-height: 24px;
  margin: 24px 0;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
  justify-content: right;

  button {
    background: transparent;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-weight: 500;
  }

  .blueButton {
    color: #ffffff;
    background: #4f75ff;

    &:hover {
      background: #3f5fd4;
    }
  }

  .blueLineButton {
    color: #4f75ff;
    border: 1px solid #4f75ff;

    &:hover {
      background: #dce3ff;
    }
  }

  .redButton {
    color: #ffffff;
    background: #ff4f5b;

    &:hover {
      background: #d43f49;
    }
  }

  .redLineButton {
    color: #ff4f5b;
    border: 1px solid #ff4f5b;

    &:hover {
      background: #ffdcde;
    }
  }
}

.closeIconDropdown {
  position: absolute;
  top: 9px;
  right: 23px;
}

.dropdown {
  font-size: 12px;
  max-width: 85px;
  width: 100%;
}
.required {
  color: #ff4f5b;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 4px;
}

.textarea {
  text-align: center;

  textarea {
    margin: 0 auto 14px;
    font-size: 14px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid #e6e7ed;
    border-radius: 4px;
    min-height: 40px;
    width: 100%;
    max-width: 375px;

    &::placeholder {
      color: #999;
    }
  }
}
.buttonsWrap {
  display: flex;
  gap: 10px;
  margin-top: 60px;
  justify-content: flex-end;
}
.modalButtonsWrap {
  display: flex;
  gap: 10px;
  margin-top: 24px;
  justify-content: center;
}
.cancelBtn {
  padding: 12px 24px;
  background-color: #fff;
  border: solid 1px #4F75FF;
  border-radius: 4px;
  color: #4F75FF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  &:hover {
    color: #0432d7;
    border-color: #0432d7;
  }
}

.deleteBtn {
  padding: 12px 24px;
  background-color: #fff;
  border: solid 1px #FF4F5B;
  border-radius: 4px;
  color: #FF4F5B;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  &:hover {
    color: #d10715;
    border-color: #d10715;
  }
}

.saveBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4F75FF;
  border: solid 1px #4F75FF;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  &:hover {
    background-color: #6486ff;
  }
  &:disabled {
    background-color: #B0B8CC;
    border-color: #B0B8CC;
    opacity: 0.6;
  }
}

.button {
  padding: 4px;
  border: solid 1px #ced4da;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-items: center;
  position: absolute; 
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    height: 18px;
    width: 18px;
  }
}

.drop > div {
  height: 40px;
  margin-bottom: 1rem;
}
