.drawer {
  position: absolute;
  top: 0;
  height: 100vh;
  background-color: #fff;
  width: 420px;
  display: flex;
  flex-direction: column;
}

.active {
  right: 0;
}

.disabled {
  right: 378px;
}

.drawerHeader {
  border-bottom: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.spaceBetween {
  justify-content: space-between;
}

.gap {
  gap: 2px;
}

.total {
  justify-content: space-between;
  align-items: flex-end;
}

.drawerBody {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  label,
  p {
    color: #5f6679 !important;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.closeIcon {
  border: none;
  background: transparent;
  padding: 0;

  svg {
    height: 20px;
    width: 20px;
  }
}

.requiredHint {
  margin: 8px 0 20px;
  color: #7f8495;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
}

.addBtn {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  margin-top: 12px;
  border-radius: 4px;
  border: none;
  background: transparent;
  color: #4f75ff;
  font-weight: 500;
  line-height: 16px;

  &:hover {
    background: #dce3ff;
  }
}

.itemActions {
  display: flex;
  gap: 8px;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    border: none;
    background: transparent;
    font-weight: 500;
    line-height: 16px;
    width: 100%;
    &:first-child {
      color: #ff4f5b;

      &:hover {
        background: #ffdcde;
      }
    }
    &:last-child {
      color: #4f75ff;

      &:hover {
        background: #dce3ff;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  margin-top: auto;

  button {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 4px;

    &:first-child {
      color: #4f75ff;
      border: 1px solid #4f75ff;
      background: transparent;

      &:hover {
        background: #dce3ff;
      }
    }
    &:last-child {
      color: #fff;
      border: none;
      background: #4f75ff;

      &:hover {
        background: #3f5fd4;
      }
      &:disabled {
        color: #abbed1;
        background: #dce3ff;
      }
    }
  }
}

.workforceItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
}

.selectedWorkforce {
  padding: 8px 10px;
  border: 1px solid #e6e7ed;
  border-radius: 4px;
}

.workforceInfo {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #40475d;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
  }
}

.arrow {
  transition: transform 0.1s ease;
  transform: rotate(270deg);

  svg {
    width: 7px;
    height: 15px;

    path {
      stroke-width: 1.5px;
      stroke: #343330;
    }
  }
}

.arrowOpen {
  transform: rotate(90deg);
}

.details {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin: 30px 0 32px;

  div {
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &:first-child {
      grid-row: 1;
    }
    &:nth-child(2) {
      grid-row: 2;
    }
    &:nth-child(3) {
      grid-row: 3;
    }

    &:nth-child(4) {
      grid-row: 1;
      grid-column: 2;
    }
    &:nth-child(5) {
      grid-row: 2;
      grid-column: 2;
      max-width: 115px;
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:nth-child(6) {
      grid-row: 3;
      grid-column: 2;
    }
    &:nth-child(7) {
      grid-row: 4;
      grid-column: 1;
    }
    &:last-child {
      grid-row: 5;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
