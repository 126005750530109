$loader-color: #4f75ff !default;

.circularLoader {
  border: solid $loader-color;
  border-top: solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
